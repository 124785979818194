import { Text, TextProps } from '../text/text';

interface Props extends TextProps {}

export const TextUppercase = ({
  children,
  className = '',
  ...props
}: Props) => {
  const classes = `uppercase font-bold font-sans tracking-wider`;
  return (
    <Text className={`${classes} ${className}`} {...props}>
      {children}
    </Text>
  );
};
