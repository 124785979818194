import { defineMessages } from '@formatjs/intl';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CategoriesModal } from '../categories-modal/categories-modal';
import TitheInput from '@components/molecules/tithe-input/tithe-input';
import { Button } from '@components/atoms/button/button';
import { PaymentCategory, PaymentCategoryType } from '@models/payment-category';
import { TitheFormEnvelopeValues } from '@hooks/use-tithe-form';

interface ResolveParams {
  orgId: string;
  category: PaymentCategoryType;
  values: TitheFormEnvelopeValues;
  setValues: (values: TitheFormEnvelopeValues) => void;
  categories: PaymentCategory[];
  setSelectedCategories: Dispatch<SetStateAction<PaymentCategory[]>>;
}

const messages = defineMessages({
  addCategoriesPreviewTitle: {
    id: 'D/W7vw',
    defaultMessage:
      '"Add Categories" is only a preview of additional categories on the envelope. You cannot add by clicking on this link.',
    description: 'Title for "Add Categories" button in preview.',
  },
});

const resolveNewCategories = ({
  orgId,
  category,
  values,
  setValues,
  categories,
  setSelectedCategories,
}: ResolveParams) => {
  setSelectedCategories(categories);
  const newValues: TitheFormEnvelopeValues = categories.reduce((prev, i) => {
    if (!values[i.id]) {
      return prev;
    }

    return {
      ...prev,
      [i.id]: values[i.id],
    };
  }, {});
  setValues(newValues);
  sessionStorage.setItem(
    `saved_categories_${orgId}_${category}`,
    JSON.stringify(categories)
  );
};

interface Props {
  orgId: string;
  category: PaymentCategoryType;
  categories: PaymentCategory[];
  allCategories?: PaymentCategory[];
  offeringOfTheWeek?: PaymentCategory;
  offeringOfTheWeekRef?: RefObject<HTMLInputElement>;
  total: number;
  values: TitheFormEnvelopeValues;
  setValues: (values: TitheFormEnvelopeValues) => void;
  isPreview?: boolean;
}

const TitheEnvelopeCategory = ({
  orgId,
  categories,
  allCategories,
  total,
  values,
  setValues,
  category,
  isPreview = false,
  offeringOfTheWeek,
  offeringOfTheWeekRef,
}: Props) => {
  const intl = useIntl();
  const [categoriesModal, setCategoriesModal] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(categories);

  const categoriesToShow = isPreview ? categories : selectedCategories;
  const offeringOfTheWeekIndex = categoriesToShow.findIndex(
    (c) => c.id === offeringOfTheWeek?.id
  );

  useEffect(() => {
    if (isPreview) {
      return;
    }

    const categoriesStr = sessionStorage.getItem(
      `saved_categories_${orgId}_${category}`
    );
    if (categoriesStr) {
      setSelectedCategories((v) =>
        (JSON.parse(categoriesStr) as PaymentCategory[]).map((c) => {
          const existingCat = v.find((_c) => _c.id === c.id);
          return {
            ...c,
            ...existingCat,
          };
        })
      );
    }
  }, [orgId, category, isPreview]);
  return (
    <div
      className={`flex flex-col sm:divide-y divide-nad-alps-night-2-500 pr-12 md:pr-0 ${
        category !== 'tithe' && categoriesToShow.length ? 'mt-4 lg:mt-8' : ''
      }`}
      data-testid={`tithe-category-${category}`}
    >
      {categoriesToShow.map((item, i) => (
        <TitheInput
          key={item.id}
          ref={offeringOfTheWeekIndex === i ? offeringOfTheWeekRef : undefined}
          isMainTitheCategory={category === 'tithe'}
          canDelete={!categories.find((i) => i.id === item.id)}
          item={item}
          value={values[item.id] ? values[item.id].amount : ''}
          isOfferingOfTheWeek={offeringOfTheWeekIndex === i}
          hideBorder={offeringOfTheWeek && offeringOfTheWeekIndex + 1 === i}
          setValue={(value) =>
            setValues({
              ...values,
              [item.id]: { amount: value || '', name: item.name },
            })
          }
          onDelete={() => {
            const newCategories = categoriesToShow.filter(
              (c) => c.id !== item.id
            );
            resolveNewCategories({
              orgId,
              category,
              values,
              setValues,
              categories: newCategories,
              setSelectedCategories,
            });
          }}
        />
      ))}
      {total > categories.length && (
        <div className="flex flex-row justify-left">
          <Button
            type="button"
            variant="link"
            icon="rightArrow"
            className="!p-0 mt-9 text-xs !w-fit"
            onClick={() => setCategoriesModal(true)}
            data-testid="add-categories"
            title={
              isPreview
                ? intl.formatMessage(messages.addCategoriesPreviewTitle)
                : ''
            }
          >
            {isPreview && (
              <FormattedMessage
                id="kAg2LT"
                defaultMessage="Add Categories (Preview Only)"
                description="Preview version of button that appears at the bottom of each tithe category."
              />
            )}
            {!isPreview && (
              <FormattedMessage
                id="dh2K/E"
                defaultMessage="Add Categories"
                description="Button that appears at the bottom of each tithe category."
              />
            )}
          </Button>
        </div>
      )}
      <CategoriesModal
        isPreview={isPreview}
        isOpen={categoriesModal}
        onUpdate={(categories) => {
          setSelectedCategories(categories);
        }}
        onClose={(categories) => {
          setCategoriesModal(false);
          resolveNewCategories({
            orgId,
            category,
            values,
            setValues,
            categories,
            setSelectedCategories,
          });
        }}
        orgId={orgId}
        type={category}
        currentCategories={categoriesToShow}
        allCategories={allCategories}
        offeringOfTheWeek={offeringOfTheWeek}
      />
    </div>
  );
};

export default TitheEnvelopeCategory;
