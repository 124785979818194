import { useState } from 'react';
import { Button, ButtonProps } from '../button/button';
import { iconColorOptions, IconTrash, IconTrashBold } from '../icons';

type ButtonAsButton = ButtonProps & {
  as?: 'button';
};
interface Props extends Omit<ButtonAsButton, 'variant' | 'children'> {
  color: iconColorOptions;
}

export const ButtonDeleteIcon = ({ color, onIsHovering, ...props }: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  onIsHovering && onIsHovering(isHovering);
  return (
    <Button
      type="button"
      {...props}
      onIsHovering={(isHovering) => setIsHovering(isHovering)}
      variant="icon"
    >
      <IconTrashBold
        className={`w-[24px] h-[24px] ${isHovering ? '' : 'hidden'}`}
        color={color}
      />
      <IconTrash
        className={`w-[24px] h-[24px] ${isHovering ? 'hidden' : ''}`}
        color={color}
      />
    </Button>
  );
};
