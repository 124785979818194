import { type ReactPlayerProps } from 'react-player';
import ReactPlayer from 'react-player/lazy';

interface Props extends ReactPlayerProps {
  url: string;
}

const VideoPlayer = ({
  url,
  controls = true,
  muted = false,
  ...props
}: Props) => {
  if (!url) {
    return <></>;
  }

  return (
    <ReactPlayer
      url={url}
      controls={controls}
      muted={muted}
      {...props}
      width="100%"
      height="100%"
    ></ReactPlayer>
  );
};

export default VideoPlayer;
