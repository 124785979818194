import {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  ReactNode,
} from 'react';

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
  footer?: ReactNode;
  shadow?: boolean;
}

const BoxTithe = forwardRef<HTMLDivElement, Props>(
  ({ children, footer, className, shadow, ...props }, ref) => {
    return (
      <div
        className={`w-full overflow-hidden lg:!border-4 ${className}`}
        {...props}
        ref={ref}
      >
        {shadow && (
          <div className="w-full h-8 shadow-lg -mb-7 rotate-180"></div>
        )}
        <div className="px-6 py-4 md:px-24 md:py-12">{children}</div>
        {footer && <div>{footer}</div>}
      </div>
    );
  }
);
BoxTithe.displayName = 'BoxTithe';

export default BoxTithe;
