import { forwardRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import currency from 'currency.js';
import Image from 'next/image';
import Message from '@components/molecules/message/message';
import { Button } from '@components/atoms/button/button';
import {
  IconCalendar,
  IconInterrogation,
  IconInterrogationFill,
  IconTithe,
} from '@components/atoms/icons';
import { PaymentCategory } from '@models/payment-category';
import { ButtonDeleteIcon } from '@components/atoms/button-delete-icon/button-delete-icon';
import { MAX_GRAND_TOTAL } from '@hooks/use-tithe-form';
import VideoPlayer from '@components/atoms/video-player/video-player';
import { TextUppercase } from '@components/atoms/text-uppercase/text-uppercase';

interface Props {
  item: PaymentCategory;
  value: string;
  setValue: (value?: string) => void;
  isMainTitheCategory: boolean;
  canDelete?: boolean;
  onDelete?: () => void;
  isOfferingOfTheWeek?: boolean;
  hideBorder?: boolean;
}

const messages = defineMessages({
  labelLearnMore: {
    id: 'c1PvSa',
    defaultMessage: 'learn more about {topic}',
    description: 'Label for mor about button',
  },
});

const TitheInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      item,
      isMainTitheCategory,
      isOfferingOfTheWeek,
      canDelete = true,
      hideBorder,
      onDelete,
      ...props
    },
    ref
  ) => {
    const intl = useIntl();
    const [isHovering, setIsHovering] = useState(false);
    const [isHoveringDelete, setIsHoveringDelete] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [showMaxValueWarning, setShowMaxValueWarning] = useState(false);
    const id = `line_item_${item.id}`;
    const hasDescription = !!(
      item.description ||
      item.photo_url ||
      item.video_url
    );
    const offeringOfTheWeekStyles = `relative before:translate-x-[-12rem] before:absolute before:inset-0 before:w-[150vw] before:h-full before:block before:bg-nad-light-blue-50`;
    const offeringOfTheWeekMessageStyles = `relative !px-0 before:translate-x-[-12rem] before:absolute before:inset-0 before:w-[150vw] before:h-full before:block before:bg-nad-alps-light`;
    hideBorder = hideBorder || isOfferingOfTheWeek;
    return (
      <>
        <div
          className={`py-4 ${hideBorder ? '!border-t-0' : ''} ${
            isOfferingOfTheWeek ? offeringOfTheWeekStyles : ''
          }`}
        >
          {isOfferingOfTheWeek && (
            <div className="relative flex items-center gap-2">
              <IconCalendar
                className="mt-0.5 sm:mt-0 w-[14px] h-[15px] sm:w-[14px] sm:h-[15px]"
                width={22}
                height={23}
              />
              <TextUppercase
                className="!text-3xs sm:!text-2xs"
                color="nad-blue"
                size="S"
              >
                <FormattedMessage
                  id="zTN+WD"
                  defaultMessage="Offering of the Week"
                  description="Offering of the week label shown above the input on the envelope page."
                />
              </TextUppercase>
            </div>
          )}
          <div className={`relative w-full flex justify-between gap-4`}>
            <div className="flex-1 flex items-center space-x-2">
              {isMainTitheCategory && (
                <span className="w-[22px] flex justify-center">
                  <IconTithe />
                </span>
              )}
              <label
                htmlFor={id}
                className={`text-nad-blue ${
                  isMainTitheCategory
                    ? 'text-m font-bold uppercase tracking-wider'
                    : 'text-xs md:text-m font-serif'
                }`}
              >
                {isMainTitheCategory && (
                  <FormattedMessage
                    id="G+yVmV"
                    defaultMessage="Tithe"
                    description='Label for "Tithe" used on envelope form.'
                  />
                )}
                {!isMainTitheCategory && item.name}
              </label>
            </div>
            <div className="w-[125px] sm:w-[150px] relative">
              <span
                className={`absolute top-[9px] left-[13px] ${
                  props.value ? 'text-nad-blue' : 'text-nad-alps-night-2'
                }`}
              >
                $
              </span>
              <CurrencyInput
                name={id}
                id={id}
                ref={ref}
                className="w-full text-nad-blue pl-[21px]"
                value={props.value}
                onValueChange={(value) => {
                  const parsedValue = currency(value || '');
                  if (parsedValue.intValue > MAX_GRAND_TOTAL.intValue) {
                    setShowMaxValueWarning(true);
                    props.setValue((MAX_GRAND_TOTAL.intValue / 100).toString());
                  } else {
                    setShowMaxValueWarning(false);
                    props.setValue(value);
                  }
                }}
                allowNegativeValue={false}
                maxLength={8}
                data-lpignore="true"
                data-testid="tithe-input"
              />
              <div className="flex absolute top-2 ml-2.5 left-full">
                <div className="flex space-x-2.5 justify-center items-center">
                  {hasDescription && (
                    <Button
                      type="button"
                      aria-label={`${intl.formatMessage(
                        messages.labelLearnMore,
                        {
                          topic: item.name,
                        }
                      )}`}
                      onClick={() => {
                        setShowDescription(!showDescription);
                        setIsHovering(false);
                      }}
                      onIsHovering={(isHovering) => setIsHovering(isHovering)}
                      variant="icon"
                    >
                      <IconInterrogation
                        width={24}
                        height={24}
                        color="nad-blue"
                        className={
                          isHovering || showDescription ? 'hidden' : ''
                        }
                      />
                      <IconInterrogationFill
                        width={24}
                        height={24}
                        color="nad-blue"
                        className={
                          isHovering || showDescription ? '' : 'hidden'
                        }
                      />
                    </Button>
                  )}
                  {canDelete && (
                    <ButtonDeleteIcon
                      color="nad-blue"
                      data-testid="tithe-input-delete"
                      onClick={() => {
                        setIsHoveringDelete(false);
                        onDelete && onDelete();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showMaxValueWarning && (
          <Message
            type="desc"
            className={`w-full ${hideBorder ? '!border-t-0' : ''} ${
              isOfferingOfTheWeek
                ? `relative ${offeringOfTheWeekMessageStyles}`
                : ''
            }`}
            textAlign="left"
          >
            <div className="relative">
              <FormattedMessage
                id="9RoB4Z"
                defaultMessage="The maximum value for a donation is {max}."
                description="Warning shown to a user if they try to enter a value greater than the max alotted (currently $20,000)."
                values={{
                  max: MAX_GRAND_TOTAL.format(),
                }}
              />
            </div>
          </Message>
        )}
        {/* Intentionally loading this with no width and height to cache image for when/if it renders. 
          Primary reason for this is to work with the 5 minute threshold of the image url.
        */}
        {item.photo_url && (
          <div className={`relative !border-t-0`}>
            <Image
              fill
              sizes="(max-width: 768px) 100vw, 540px"
              alt=""
              className="object-cover"
              src={item.photo_url}
            />
          </div>
        )}
        {item && showDescription && (
          <TitheInputDescription
            {...item}
            className={`${hideBorder ? '!border-t-0' : ''} ${
              isOfferingOfTheWeek ? `${offeringOfTheWeekMessageStyles}` : ''
            }`}
          />
        )}
      </>
    );
  }
);
TitheInput.displayName = 'TitheInput';
export default TitheInput;

interface DescriptionParams {
  description: string;
  photo_url?: string;
  video_url?: string;
  className?: string;
}
const TitheInputDescription = (params: DescriptionParams) => {
  return (
    <Message
      type="desc"
      className={`relative w-full space-y-4 ${params.className || ''}`}
      textAlign="left"
    >
      {params.description && (
        <div
          className="relative space-y-2 whitespace-pre-line"
          dangerouslySetInnerHTML={{ __html: params.description }}
        ></div>
      )}
      {params.photo_url && (
        <div className="relative aspect-video">
          <Image
            fill
            sizes="(max-width: 768px) 100vw, 540px"
            alt=""
            className="object-cover"
            src={params.photo_url}
          />
        </div>
      )}
      {params.video_url && (
        <div className="relative aspect-video">
          <VideoPlayer url={params.video_url}></VideoPlayer>
        </div>
      )}
    </Message>
  );
};
