import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from '../../atoms/button/button';
import Checkbox from '../../atoms/checkbox/checkbox';
import { IconBuild, IconChurch, IconClose, IconWorld } from '../../atoms/icons';
import InputText from '../../atoms/input-text/input-text';
import Modal, { modalMessages } from '@components/molecules/modal/modal';
import { PaymentCategory, PaymentCategoryType } from '@models/payment-category';
import { useEnvelopeCategories } from '@hooks/use-envelope';
import { HeadingUppercase } from '@components/atoms/heading-uppercase/heading-uppercase';
import { Text } from '@components/atoms/text/text';
import { TextUppercase } from '@components/atoms/text-uppercase/text-uppercase';

interface Props {
  isOpen: boolean;
  isPreview: boolean;
  onUpdate: (categories: PaymentCategory[]) => void;
  onClose: (categories: PaymentCategory[]) => void;
  orgId: string;
  currentCategories: PaymentCategory[];
  allCategories?: PaymentCategory[];
  offeringOfTheWeek?: PaymentCategory;
  type: PaymentCategoryType;
}

const messages = defineMessages({
  hTithe: {
    id: 'Pf8Tz/',
    description:
      'Heading for more tithe offerings that appears at the top of the "add more categories" modal',
    defaultMessage: 'More Tithe Offerings',
  },
  hLocal: {
    id: 'jUXS7P',
    description:
      'Heading for more local offerings that appears at the top of the "add more categories" modal',
    defaultMessage: 'More Local Offerings',
  },
  hConf: {
    id: 'aGN1gP',
    description:
      'Heading for more conference/union offerings that appears at the top of the "add more categories" modal',
    defaultMessage: 'More Conference/Union Offerings',
  },
  hWorld: {
    id: 'MO5HkI',
    description:
      'Heading for more world offerings that appears at the top of the "add more categories" modal',
    defaultMessage: 'More World Offerings',
  },
  search: {
    id: 'CAengo',
    description: 'Placeholder used to search offering categories',
    defaultMessage: 'Search Categories',
  },
  categoriesSelected: {
    id: 'wX+jkH',
    defaultMessage:
      '{count} {count, plural, one {category} other {categories}} selected',
    description: 'Label for the number of categories selected',
  },
  labelBack: {
    id: 'UkhFPv',
    defaultMessage: 'Back to Donation',
    description: 'Label for back button',
  },
});

export const CategoriesModal = ({
  orgId,
  currentCategories,
  type,
  isOpen,
  isPreview = false,
  allCategories: _allCategories,
  offeringOfTheWeek,
  ...props
}: Props) => {
  const intl = useIntl();
  const { data: allEnvelopeCategories } = useEnvelopeCategories({
    orgId,
    type,
    shouldFetch: isOpen && !_allCategories,
  });
  const allCategories = _allCategories || allEnvelopeCategories;
  const [hasSorted, setHasSorted] = useState(false);
  const [checkedCategories, setCheckedCategories] = useState(currentCategories);
  const [filteredCategories, setFilteredCategories] = useState(allCategories);
  const [searchText, setSearchText] = useState<string>('');
  const [counter, setCounter] = useState(checkedCategories.length);

  useEffect(() => {
    if (isOpen) {
      const currentCategoryIDs = currentCategories.map((c) => c.id);
      setCheckedCategories(currentCategories);
      if (allCategories && !hasSorted) {
        setFilteredCategories(
          (allCategories || []).sort((a, b) => {
            const aSelected = Number(currentCategoryIDs.includes(a.id));
            const bSelected = Number(currentCategoryIDs.includes(b.id));
            if (aSelected && bSelected) {
              return 0;
            } else if (aSelected || bSelected) {
              return bSelected - aSelected;
            } else {
              return a.name.localeCompare(b.name);
            }
          })
        );
        setHasSorted(true);
      }
      setSearchText('');
      setCounter(checkedCategories.length);
    }
  }, [
    isOpen,
    currentCategories,
    allCategories,
    hasSorted,
    checkedCategories.length,
  ]);

  const close = () => {
    setHasSorted(false);
    props.onClose(checkedCategories);
  };
  return (
    <Modal
      onClose={() => {
        close();
      }}
      isOpen={isOpen}
      size="S"
      variant="paddingLess"
      data-testid="categories-modal"
      className="px-6"
    >
      <div className="w-full flex flex-col flex-fixed space-y-6 relative container-s pb-6">
        <div className="flex items-center mt-6">
          <div className="flex  flex-row space-x-1 leading-9 items-center">
            {type === 'church' && (
              <IconChurch
                width={26}
                height={23}
                color="nad-blue"
                size="S"
                className="w-7 h-7"
              />
            )}
            {type === 'church.parent' && (
              <IconBuild
                width={26}
                height={23}
                color="nad-blue"
                size="S"
                className="w-7 h-7"
              />
            )}
            {type === 'world' && (
              <IconWorld
                width={26}
                height={23}
                color="nad-blue"
                size="S"
                className="w-7 h-7"
              />
            )}
            <HeadingUppercase variant="h5" color="nad-blue">
              {type === 'tithe' && intl.formatMessage(messages.hTithe)}
              {type === 'church' && intl.formatMessage(messages.hLocal)}
              {type === 'church.parent' && intl.formatMessage(messages.hConf)}
              {type === 'world' && intl.formatMessage(messages.hWorld)}
            </HeadingUppercase>
          </div>
          <Button
            type="button"
            variant="icon"
            onClick={() => close()}
            aria-label={intl.formatMessage(modalMessages.close)}
            className={`sticky top-3 left-full right-3 p-1 md:hidden`}
            data-testid="modal-close"
          >
            <IconClose width={11} height={11} className="w-4 h-4" />
          </Button>
        </div>

        <InputText
          aria-label={intl.formatMessage(messages.search)}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            setFilteredCategories(
              allCategories?.filter((i) =>
                i.name.toLowerCase().includes(e.target.value.toLowerCase())
              )
            );
          }}
          placeholder={intl.formatMessage(messages.search)}
          className="rounded-full w-full pl-4 py-2 placeholder:text-noto-sans placeholder:italic placeholder:text-nad-alps-night placeholder:text-xs placeholder:m-4 border border-nad-alps-night-2"
          autoComplete="off"
        />
      </div>
      <div className="flex-initial flex flex-col overflow-y-auto container-s px-4">
        <div className="w-full space-y-6 flex flex-col">
          <div className="flex flex-col py-6 space-y-6">
            {(filteredCategories || allCategories)?.map((item) => (
              <Checkbox
                key={item.id}
                textClassName="text-nad-blue font-serif"
                checked={!!checkedCategories.find((i) => i.id === item.id)}
                label={item.name}
                labelBadge={
                  offeringOfTheWeek?.id === item.id ? (
                    <TextUppercase
                      className="min-[500px]:whitespace-nowrap !text-3xs sm:!text-s"
                      size="S"
                    >
                      Offering of the Week
                    </TextUppercase>
                  ) : undefined
                }
                className={`border`}
                data-testid="category-checkbox"
                disabled={isPreview}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCounter(counter + 1);
                    const newCategories = [...checkedCategories, item];
                    setCheckedCategories(newCategories);
                    props.onUpdate(newCategories);
                  } else {
                    counter > 0 ? setCounter(counter - 1) : '';
                    const filteredCategories = checkedCategories.filter(
                      (i) => i.id !== item.id
                    );
                    setCheckedCategories(filteredCategories);
                    props.onUpdate(filteredCategories);
                  }
                }}
              />
            ))}
          </div>
        </div>
      </div>
      {
        <div className="border-nad-light-blue border-t-[3px] p-3 w-full h-fit flex-fixed  ">
          <Text size="S" color="nad-blue-2" className="font-bold uppercase">
            <>
              {intl.formatMessage(messages.categoriesSelected, {
                count: counter,
              })}
            </>
          </Text>
          <Button
            size="small"
            className="!w-full mt-4 sm:mt-6 mb-4 !p-4"
            onClick={() => {
              close();
            }}
          >
            {intl.formatMessage(messages.labelBack)}
          </Button>
        </div>
      }
    </Modal>
  );
};
