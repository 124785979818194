import {
  ApiHook,
  buildUrlQuery,
  useCreateSWREndpoint,
} from './use-swr-endpoint';
import {
  PaymentCategories,
  PaymentCategory,
  PaymentCategoryType,
} from '@models/payment-category';

export interface EnvelopeParams {
  orgId?: string;
}

export interface EnvelopeCategoriesParams {
  orgId: string;
  type?: PaymentCategoryType;
  shouldFetch?: boolean;
}

export const useEnvelope = ({
  orgId,
}: EnvelopeParams): ApiHook<PaymentCategories> => {
  const url = `/api/v3/envelope/${orgId}`;
  const shouldFetch = !!orgId;
  return useCreateSWREndpoint<PaymentCategories>({ url, shouldFetch });
};

export const useEnvelopeCategories = ({
  orgId,
  type,
  shouldFetch = true,
}: EnvelopeCategoriesParams): ApiHook<PaymentCategory[]> => {
  const query = buildUrlQuery({
    payment_group_id: type,
  });
  const url = `/api/v3/payment_categories/${orgId}${query}`;
  shouldFetch = shouldFetch && !!orgId;
  return useCreateSWREndpoint<PaymentCategory[]>({ url, shouldFetch });
};
